.c-image-box {
  position: relative;
  z-index: 1;

  &__header {
    padding: rem(150px) 0;

    &-content {
      padding: 0 rem(30px);
    }

  }

  &-main {
    &__background {
      position: relative;
      overflow: hidden;
      left: -7vw;
      width: calc(100% + 14vw);
      height: 15rem;


      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);

      }

      @media(min-width: $from-small) {
        left: -9rem;
        height: 28rem;
        width: calc(100% + 18rem);

      }

      @media(min-width: $from-medium) {
        height: 45rem;
        width: calc(100% + 9rem);
        border-radius: 0 10px 0 0;
      }
    }

    &__content {
      
      background-color: #ffb71c;
      padding: rem(50px);

      position: relative;
      left: -7vw;
      width: calc(100% + 14vw);
      z-index: 1;

      

      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);
      }

      @media(min-width: $from-small) {
        width: calc(100% + 18rem);
        left: -9rem;
      }

      @media(min-width: $from-medium) {
        padding: rem(100px);
        border-radius: 5px 0 0 5px;


      }

    }

    &__cta {

      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);
        padding: rem(60px);
      }

      @media(min-width: $from-small) {
        width: calc(100% + 18rem);
        left: -9rem;
      }

      @media(min-width: $from-medium) {
        width: calc(100% + 9rem);
        padding: rem(90px);
        left: initial;

      }

      left: -7vw;
      width: calc(100% + 14vw);
      position: relative;

      padding: rem(30px);
      background-color:#F9F9F9;
      text-align: center;

      a {
        margin: 0 auto;
      }
    }

  }
  .hide-mobile {
    @media(max-width: $to-medium) {
      display: none;
    }
  }
}