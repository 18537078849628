

.global-menu {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	pointer-events: none;
	z-index: 100;

	@media(min-width: $from-medium) {
		width: 50vw;
		height: 80vh;
	}

	&__wrap {
		@media(min-width: $from-medium) {
			padding: rem(30px) 0;
		}
	}

}

.global-menu__item {
	color: $color;
	font-size: rem(40px);

	font-weight: bold;
	opacity: 0;
	transform: translateY(-100%);
	pointer-events: none;
	display: block;
	margin: 0.25em 0;
	transition: transform 0.3s, opacity 0.3s;
	transition-timing-function: ease-in;

	&.-remove {
		> a {
			cursor: default;
			&:hover {
				color: $color;
			}

		}
	}

	@media(max-width: $to-medium) {
		&.-remove {
			display: none;
		}
		font-size: rem(38px);
	}

	a {
		color: $color;
		transition: 0.3s all ease-in-out;

		&:hover {
			color: $accent
		}

		&:active {
			color: $accent;
			transition: 0s all ease-in-out;
		}
	}

	 ul {
		border-left: 3px solid $accent;
	}

	ul > li {
		font-size: rem(21px);
		margin-left: rem(20px);
	}
}


.global-menu__item--melt {
	transition: transform 0.1s, opacity 0.1s;
	transform: translateY(100%);
	transition-timing-function: ease-out;
}


.global-menu__item:hover {
	color: $accent;
}

.global-menu__item.is-opened {
	opacity: 1;
	transform: translateY(0) rotate(0);
	pointer-events: auto;
	transition-timing-function: ease;
}


.global-menu__item:nth-of-type(1) {
	transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(1) {
	transition-delay: 0.85s;
}

.global-menu__item:nth-of-type(2) {
	transition-delay: 0.05s;
}

.global-menu__item.is-opened:nth-of-type(2) {
	transition-delay: 0.8s;
}

.global-menu__item:nth-of-type(3) {
	transition-delay: 0.1s;
}

.global-menu__item.is-opened:nth-of-type(3) {
	transition-delay: 0.75s;
}

.global-menu__item:nth-of-type(4) {
	transition-delay: 0.15s;
}

.global-menu__item.is-opened:nth-of-type(4) {
	transition-delay: 0.7s;
}




.global-menu__item--melt.is-opened {
	transition-duration: 0.3s;
}

.global-menu__item--melt:nth-of-type(1) {
	transition-delay: 0s;
}

.global-menu__item--melt.is-opened:nth-of-type(1) {
	transition-delay: 0.65s;
}

.global-menu__item--melt:nth-of-type(2) {
	transition-delay: 0s;
}

.global-menu__item--melt.is-opened:nth-of-type(2) {
	transition-delay: 0.7s;
}

.global-menu__item--melt:nth-of-type(3) {
	transition-delay: 0s;
}

.global-menu__item--melt.is-opened:nth-of-type(3) {
	transition-delay: 0.75s;
}

.global-menu__item--melt:nth-of-type(4) {
	transition-delay: 0s;
}

.global-menu__item--melt.is-opened:nth-of-type(4) {
	transition-delay: 0.8s;
}


.shape-overlays {
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;

	@media(min-width: $from-medium) {
		width: 50vw;
		height: 80vh;
		border-radius: 0 0 0 10px;
	}
}

.shape-overlays.is-opened {
	pointer-events: auto;

}

.shape-overlays__path:nth-of-type(1) {
	fill: #000000
}

.shape-overlays__path:nth-of-type(2) {
	fill:  $accent-3
}

.shape-overlays__path:nth-of-type(3) {
	fill: $accent-2;
}

