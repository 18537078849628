// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #ffffff;
$black: #000000;
$red: #DB0007;

$accent: #DB0007;
$accent-2: #FFBC0D;
$accent-3: #292929;
$light-bg: #F9F9F9;
$dark-bg: #051930;

$gray-1: #4a4a4a;
$gray-2: #9b9b9b;
$gray-3: #eeeeee;
$gray-4: #808285;

$footer-bg: #33353e;
$bg: #000;
$bg-body: #fff;

// Specific
// =============================================================================
// Link
$link-color:       #1A0DAB;
$link-focus-color: #1A0DAB;
$link-hover-color: darken(#1A0DAB, 10%);
// Selection
$selection-text-color:       #000;
$selection-background-color: $accent-2;


// Social Colors
// =============================================================================
$facebook-color: #3b5998;
$instagram-color: #e1306c;
$youtube-color: #cd201f;
$twitter-color: #1da1f2;
$linkedin-color: #0077b5;

// Customs
$cursor-color: $accent-2;
$loader-bg: #FFBC0D;
$border-line: rgba(182, 184, 185, 0.29);
