[data-namespace="home-video"] {
  .c-main-hero {
    height: auto;
    min-height: auto;
    background-color: #fff;

    &::before {

      display: none;

      .is-loaded & {
        opacity: 1;
        transition: 0.8s;
        transition-delay: 1.3s;
      }

      @media(min-width: $from-medium) {
        content: '';
        display: block;
        background-image: url(../../assets/images/McD_GoldenArches_cropped.svg);
        background-size: cover;
       

        background-repeat: no-repeat;
    background-position: left 20px;
    bottom: 0;
    width: 65%;
    position: absolute;
    height: 50%;
        left: 0;
  
  
        opacity: 0;
        transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;

      }

      @media(min-width: $from-big) {
        background-size: contain;

        background-position: left bottom;
        
      }

    }
  }


  &-video {
    position: relative;
    // clip-path: inset(0% 3rem round 4px);
    // margin-right: -9rem;
    // margin-left: -9rem;
  }


  .c-main-hero-video__wrapper {
    background-color: $gray-3;
    // .o-container{
    //   padding: 0 3rem;
    // }
  }

  .c-main-hero-video {
    overflow: hidden;
    @media(min-width: $from-medium) {
      height: rem(720px);
    }
  }

  .c-main-hero__background,
  .c-main-hero__video {
    position: relative;
  }

  .c-main-hero__title,
  .c-main-hero__subtitle-content {
    color: $accent-3;
  }

  .c-main-hero__title {

    @media (min-width: $from-medium) {
      font-size: 4.254343294vw;
    }

  }

  .c-main-hero__inner {
    height: 100%;
  }

  .c-main-hero__container {

    height: 100%;
    margin: 8rem 0px;

    @media(max-width: $from-small) {
      margin: 5rem 0px;
      margin-top: 2rem;
    }

  }

  .__scroll {
    right: 0;
    left: initial;
    // top: auto;

    .is-safari & {
      top: 8rem;
    }

    .scroll-text {
      color: $accent-3
    }

    .scroll-arrow svg {
      fill: $accent-3
    }
  }

  .c-olivia-cta {
    @media(min-width: $from-medium) {
      width: 60%
    }
  }

  .c-main-hero__title-bg {
    background-color: $accent-2;
  }



}

.c-main-hero {
  position: relative;
  background-color: $accent-3;
  height: 80vh;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  // user-select: none;
  min-height: 760px;

  [data-namespace="interior"] & {
    border-bottom: 5px solid $accent;
    background-color: #fff;
  }

  @media (max-width: $from-tiny) {
    height: 100vh;
    min-height: 760px;
  }

  @media (min-width: $from-medium) {
    min-height: 100vh;
    height: 700px;

    [data-namespace="interior"] & {
      min-height: 50vh;
      height: 600px;
    }

  }


  @media (min-width: $from-huge) {
    min-height: 100vh;
    height: 1200px;

    [data-namespace="interior"] & {
      min-height: 30vh;
      height: 1000px;
    }

  }


}

.c-main-hero__inner {
  position: relative;
  text-align: left;
  padding-top: 6rem;
  height: 100%;
  display: block;

  @media (min-width: $from-medium) {
    padding-top: 6rem;
    padding-bottom: 0;
  }
}

.c-main-hero__background {
  .is-loaded & {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 100%, 0);
  overflow: hidden;

  &-wrap {
    .o-background {
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
    }
  }
}

.c-main-hero__video {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  overflow: hidden
}

.browserIE .c-main-hero__video {
  height: auto
}


.c-main-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-image: linear-gradient(165deg, #000000 10%, rgba(0, 0, 0, 0.3098) 55%, #000000 95%, #222222 100%);

  pointer-events: none;

  @media (min-width: $to-tiny) {
    background-image: linear-gradient(165deg, #000000 10%, rgba(0, 0, 0, 0.3098) 55%, #000000 95%, #222222 100%);
    // mix-blend-mode: multiply;
  }
}

.c-main-hero__container {
  height: 100%;

  @media(min-width: $from-medium) {
    height: calc(100% - 3rem);

    [data-namespace="interior"] & {
      height: 100%;
    }
  }
}

.c-main-hero__layout {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: $from-medium) {
    flex-flow: column;
  }

  &-item {
    @media (max-width: $from-medium) {
      display: flex;
      align-items: center;
      height: calc(50% + 15rem);

      [data-namespace="interior"] & {
        height: 50%;
      }
    }

  }
}

.c-main-hero__content {
  text-align: center;
  // overflow: hidden;
  position: relative;
  width: 100%;
  will-change: transform;

  @media (min-width: $from-medium) {
    padding-top: 0;
    text-align: left;

  }
}

.c-main-hero__title {
  .is-loaded & {
    opacity: 1;
  }

  font-family: $font-title;

  transition: background-color 0.6s linear,
  opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94),
  transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  margin: 0 auto;
  color: #fff;
  width: 100%;
  position: relative;
  font-weight: bold;
  text-transform: none;

  opacity: 0;
  line-height: normal;
  font-size: 8.5836909871vw;

  @media (min-width: $from-small) and (max-width: $to-medium) {
    font-size: 4.6911649726vw;
  }

  @media (min-width: $from-medium) {
    font-size: 3.254343294vw;
  }

  &--em {
    text-transform: uppercase;
    font-family: $font-default;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: normal;
    color: $accent;
    display: block;
    margin-bottom: rem(15px);
  }
}

.c-main-hero__title-inner {
  position: relative;
  display: block;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: clip-path 0.8s cubic-bezier(1, 0, 0, 1) 0.3s;

  .is-loaded & {
    clip-path: polygon(0 0, 100% 0, 100% 180%, 0 180%);
  }

  @media (min-width: $from-medium) {
    display: inline-block;
  }

  .-period {
    color: $accent;
  }
}

.c-main-hero__title-bg {
  .is-loaded & {
    height: 0;
  }

  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 200vw;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  height: 100%;
  -webkit-transition: height 0.6s cubic-bezier(1, 0, 0, 1) 1s;
  transition: height 0.6s cubic-bezier(1, 0, 0, 1) 1s;
}

.c-main-hero__link {
  @media (min-width: $from-medium) {
    margin-top: rem(40px);
  }

  background-color: transparent;

  .o-button__label {
    color: #fff;
  }
}

.c-main-hero__subtitle {
  .is-loaded & {
    opacity: 1;
    transform: translateY(0%);
  }

  opacity: 0;
  transform: translateY(-20%);
  transition: opacity,
  transform;
  transition-duration: 0.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  display: block;

  @media (min-width: $from-large) {
    width: 70%;
  }

  &-content {
    color: #fff;
    margin: 0;
    font-size: rem(24px);
  }

  &-message {
    margin: rem(30px) 0;
    @media (min-width: $from-medium) {
      margin-top: rem(10px);
    }

    @media(max-width: $from-small) {
      margin-top: 0;
      margin-bottom: rem(40px);
    }
  }
}

.c-main-hero__blurb {
  .is-loaded & {
    opacity: 1;
    transform: translateY(0%);
  }

  opacity: 0;
  transform: translateY(100%);
  transition: opacity,
  transform;
  transition-duration: 0.6s;
  transition-delay: 2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 3rem;

  @media (min-width: $from-large) {
    width: 50%;
    padding: 3rem;
    max-width: calc(100% - 18rem);
  }



  &-content {
    color: #fff;
    margin: 0;
    font-size: 1.3rem;
  }

  &-title {
    font-family: $font-speedee;
    font-size: rem(28px);
    position: relative;

    &:after {
      content: "";
      display: block;
      margin: rem(10px) 0;
      width: rem(70px);
      background-color: $accent;
      height: rem(3px);
      border-radius: 10px;
    }
  }

  &-text {
    margin-top: rem(30px);
    font-size: rem(16px);
  }
}

.c-main-hero__social {
  display: none;

  input {
    display: none;
  }

  label {
    display: block;
  }

  @media (min-width: $from-medium) {
    position: absolute;
    width: 9rem;
    right: 0;
    bottom: rem(60px);
    height: auto;
    display: block;
    z-index: 999;
  }

  &-list {
    padding: 0;
    display: flex;
    width: 100%;
    height: 25rem;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }

  &-item {
    list-style: none;
  }

  &-link {
    border-radius: 100%;
    border: 1px solid #fff;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 auto;
    display: none;
    align-items: center;
    justify-content: center;

    &.show {
      display: flex;
    }

    &.-social-hub {
      display: flex;

      &.-open {
        border-color: #f47721;
      }

      &:hover {
        svg {
          fill: #f47721;
        }
      }
    }

    &.-twitter {
      &:hover {
        svg {
          fill: $twitter-color;
        }
      }
    }

    &.-linkedin {
      &:hover {
        svg {
          fill: $linkedin-color;
        }
      }
    }

    &.-facebook {
      &:hover {
        svg {
          fill: $facebook-color;
        }
      }
    }

    &.-youtube {
      &:hover {
        svg {
          fill: $youtube-color;
        }
      }
    }

    &.-instagram {
      &:hover {
        svg {
          fill: $instagram-color;
        }
      }
    }

    svg {
      width: 0.8rem;
      height: 0.8rem;
      fill: #fff;
      transition: 0.3s all ease-in-out;
    }
  }
}

.__scroll {
  display: none;

  @media screen and (min-width: $from-medium) {
    position: absolute;
    left: 0;
    width: 9rem;
    bottom: rem(300px);
    height: auto;
    display: block;
    z-index: 1;
    cursor: pointer;

    &:hover {
      .scroll-text {
        opacity: 0.7;

        transform: translateX(10px);
      }

      .scroll-arrow {
        transform: translateX(15px) scale(0.9);

        &::before {
          transform: rotate(90deg) scale(0.9);
          opacity: 0.7;
        }
      }
    }
  }
}

.scroll-wrap {
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-text {
  @media screen and (min-width: $from-medium) {
    float: left;
    color: #fff;
    pointer-events: none;
    font-size: 12px;
    margin: 1px 0 0;
    white-space: nowrap;
    line-height: 43px;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.3s ease-in-out;
  }
}

.scroll-arrow {
  @media screen and (min-width: $from-medium) {
    position: relative;
    float: left;
    pointer-events: none;

    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.3s ease-in-out;
    margin: 0 0 0 18px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  &::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    left: 0px;
    right: 0;
    top: -1px;
    position: absolute;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 2px solid $accent;
    border-bottom: 2px solid transparent;
    border-top: 2px solid $accent;

    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1),
      opacity 0.3s ease-in-out;

    transform: rotate(90deg);
  }

  svg {
    fill: #fff;
    width: 20px;
    height: 30px;
    transform: rotate(-90deg);
    position: relative;
    top: -1px;
  }
}