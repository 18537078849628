[data-component="golden-arches"] {
  position: relative;
  display: block;
  z-index: 1;
  canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}
.c-golden-arches {
  position: relative;
  display: block;


  @media(min-width: $from-medium) {
    margin-top: rem(120px);
  }

  &__content {
    position: relative;
    

    &::before {
      content: '';
      display: block;
      background-image: url(../../assets/images/left-mcD.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      bottom: 0;
      width: rem(200px);
      position: absolute;
      height: rem(300px);
      opacity: 0.2;
      left: 0;
      z-index: -1;
    }
  }


  &__horizontal {
    display: none;
    @media(min-width: $from-medium) {
      display: block;
    }
    text-align: center;
    will-change: transform;

    h3 {
      text-transform: uppercase;
      font-size: rem(100px);
      white-space: nowrap;
      margin: 0;
    }
  }

  &__gallery {
    position: relative;
    height: rem(500px);
    overflow: hidden;
    border-top: rem(10px) solid $accent-2;
    background: $accent-2;
    @media(min-width: $from-medium) {
      margin-top: -30px;

    }

    &::before {
      content: '';
      display: block;
      background-image: url(../../assets/images/gooe.svg);
      width: rem(200px);
      height: rem(100px);
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      left: 20%;
      z-index: 2;
      top: 0;
    }


  }
}
